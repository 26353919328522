import $ from 'jquery';
import { getWeekday, DAY_CLASSES } from 'date_utils';
import { selectDefaultHours, setHoursForDay } from 'booking_tab_utils';
import translate from 'i18n';

const openBookingHoursModal = (bookingId, dateClicked) => {
  if (!dateClicked) {
    //Set the date to today
    dateClicked = new Date().setHours(0, 0, 0, 0);
  }

  const title = translate('booking.book_hours');

  $('.time-form')
    .dialog({
      modal: true,
      show: 'show',
      width: 540,
      title: title,
      dialogClass: 'booking-dialog'
    })
    .prev()
    .find('.ui-dialog-titlebar-close')
    .hide();

  //Set the bookingId
  $('.time-form').find(
    "input#booked_time_booking_id[type='hidden']"
  )[0].value = bookingId;

  //Clear up old status
  $('.falert').hide();
  $('.warning').empty();
  $('.warning').hide();
  $('.booking').prop('disabled', false);

  //Set the date
  $('#booked_time_date_3i').val(dateClicked.getDate());
  $('#booked_time_date_2i').val(dateClicked.getMonth() + 1);
  $('#booked_time_date_1i').val(dateClicked.getFullYear());

  $('#booked_time_finish_3i').val(dateClicked.getDate());
  $('#booked_time_finish_2i').val(dateClicked.getMonth() + 1);
  $('#booked_time_finish_1i').val(dateClicked.getFullYear());

  if ($('.day').length > 0) {
    $('.day')[0].innerText = getWeekday(dateClicked);
  }

  // Set the default hour listing
  const hours = selectDefaultHours(dateClicked);
  $('.total')[0].innerText = hours;

  var dayOfWeek = dateClicked.getDay();
  $(DAY_CLASSES[dayOfWeek]).find('.day-combined')[0].innerText = hours;

  const bookingForId = $('select#booking_user_id').val();
  var currentUserId = $('.current-user')[0].innerText;

  if (bookingForId === currentUserId) {
    $('.user-warn').addClass('hidden');
  } else {
    $('.user-warn').removeClass('hidden');
    var userName =
      $('select#booking_user_id')
        .find('option:selected')
        .text() || $('.booking-user-name').val();
    $('.user-for-warning').text(userName);
  }
};

export default openBookingHoursModal;
