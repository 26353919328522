import $ from 'jquery';
import { getCategory } from 'booking_category_utils';
import { shortDateWithYearAndDay, shortDateWithYear } from 'date_utils';
import { defaultHoursFor, getWorkingHoursForDate } from 'working_hour_utils';
import { showTimeTab } from './booking_form_tabs';
import translate from 'i18n';

const bootstrap = require('bootstrap');

const updateTabs = category => {
  if (!category) {
    category = getCategory();
  }

  //States
  const isPreexisting = $('#booking_id').val() != '';
  const isWorking = category == 'project';
  // Can edit if currentUser = bookedFor | owner

  const workingTab = $('#working-tab');
  const workingTabObj = bootstrap.Tab.getOrCreateInstance(
    document.querySelector('#working-tab')
  );

  const notWorkingTab = $('#not-working-tab');
  var notWorkingTabObj = null;
  if (notWorkingTab.length > 0) {
    notWorkingTabObj = bootstrap.Tab.getOrCreateInstance(
      document.querySelector('#not-working-tab')
    );
  }

  const hoursTab = $('#hours-tab');
  var hoursTabObj = null;
  if (hoursTab.length > 0) {
    hoursTabObj = bootstrap.Tab.getOrCreateInstance(
      document.querySelector('#hours-tab')
    );
  }

  if (isPreexisting) {
    if (isWorking) {
      notWorkingTab.hide();
      workingTab.show();
      workingTabObj.show();
      hoursTab.show();
      hoursTabObj.show();
      showTimeTab();
    } else {
      if (notWorkingTabObj) {
        notWorkingTabObj.show();
      }
      notWorkingTab.show();
      workingTab.hide();
      hoursTab.hide();
    }
  } else {
    hoursTab.hide();
    workingTab.show();
    workingTabObj.show();
    if (userCanEdit()) {
      notWorkingTab.show();
    } else {
      notWorkingTab.hide();
    }
  }
};

const userCanEdit = () => {
  const owner = $('.owner')[0].innerText === 'true';
  const currentUser = $('.current-user')[0].innerText;
  const bookingFor = $('select#booking_user_id').val();

  var userCanEdit = currentUser == bookingFor || owner;
  // Can edit if the booking has not yet been assigned
  if (bookingFor == 0 || bookingFor === null) {
    userCanEdit = true;
  }

  return userCanEdit;
};

const lastDayWithHours = () => {
  const workingHoursData = $('.user-hours').data().hours;
  let lastDay = new Date();
  lastDay.setDate(lastDay.getDate() - 1);

  // Risk of infinite loop. Check we have some data to make sure we don't get there
  if (workingHoursData.some(entry => entry > 0)) {
    let hours = getWorkingHoursForDate(workingHoursData, lastDay);
    while (hours <= 0) {
      lastDay.setDate(lastDay.getDate() - 1);
      hours = getWorkingHoursForDate(workingHoursData, lastDay);
    }
  }

  return lastDay;
};

const loadBookingHours = booking_id => {
  // Clear old data first
  $('.booked-hours-rows').empty();

  $.ajax({
    url: '/booking_booked_times?booking_id=' + booking_id
  }).done(function(response) {
    if (response) {
      const lastDay = shortDateWithYear(lastDayWithHours());

      $('.btn.yesterday').prop('title', lastDay);

      $('.btn.yesterday').prop('disabled', false);
      $('.btn.yesterday').removeClass('disabled');

      $('.btn.today').prop('disabled', false);
      $('.btn.today').removeClass('disabled');

      $('.secondary.lots').prop('disabled', false);
      $('.secondary.lots').removeClass('disabled');

      if (response.length > 0) {
        response.forEach(function(bookedTime) {
          addTimeRow(bookedTime);
        });
      }
    }
  });
};

const populateTimeRow = (date, contextClass) => {
  const currentUser = $('.current-user-name')[0].innerText;
  const workingHoursData = $('.user-hours').data().hours;
  const hours = getWorkingHoursForDate(workingHoursData, date);
  const invoiceCheckbox = $('.booking_invoice').closest('.checkbox-holder');

  const newBookedTime = {
    user_name: currentUser,
    hours: hours,
    description: '',
    date: date,
    has_invoice: invoiceCheckbox.hasClass('checked')
  };

  addTimeRow(newBookedTime, true, contextClass);
};

const addTimeRow = (bookedTime, newEntry, contextClass) => {
  const date = new Date(bookedTime.date);
  const dateText = shortDateWithYear(date);
  const $rows = $('.booked-hours-rows');
  const today = shortDateWithYear(new Date());
  var duplicate = false;

  if (newEntry) {
    var indexOfDate = -1;

    // Feels like there's a more efficient way to do this,
    // but wasn't able to find a way to reliably get the text with white spaces
    $rows.find('.row').each(function(index, row) {
      if (row.innerText.startsWith(dateText)) {
        duplicate = true;
      }
    });

    if (duplicate) {
      $('.warn-hours').show();
      return;
    }
  }

  if (dateText == today) {
    $('.btn.today').prop('disabled', true);
    $('.btn.today').addClass('disabled');
  } else if (dateText == $('.btn.yesterday').prop('title')) {
    $('.btn.yesterday').prop('disabled', true);
    $('.btn.yesterday').addClass('disabled');
  }

  var $form = null;

  // Create row
  const rowDiv = document.createElement('div');
  rowDiv.className = 'tr row g-0 ';
  rowDiv.setAttribute('data-id', bookedTime.id);

  if (newEntry) {
    const formName = 'new_booked_time'; // + rowId;

    $form = document.createElement('form');
    $form.name = formName;
    $form.classList = 'new-booked-time ' + contextClass;
    $form.id = formName;

    var bookingId = $('#booking_id').val();
    if (!bookingId) {
      bookingId = $('#booked_time_booking_id').val();
    }
    const bookingIdInput = document.createElement('input');
    bookingIdInput.name = 'booking_id';
    bookingIdInput.id = 'booking_id';
    bookingIdInput.classList = 'hidden booking-id';
    bookingIdInput.value = bookingId;
    rowDiv.append(bookingIdInput);
  }

  //Create date cell
  const dateDiv = document.createElement('div');
  dateDiv.className = 'col-3 col-md-2';

  const dateContents = document.createElement('span');
  dateContents.className = 'date';
  dateContents.innerHTML = dateText;
  dateContents.title = shortDateWithYearAndDay(date);

  dateDiv.appendChild(dateContents);
  rowDiv.appendChild(dateDiv);

  if (newEntry) {
    const dateInput = document.createElement('input');
    dateInput.name = 'booked_time[date]';
    dateInput.id = 'booked_time[date]';
    dateInput.classList = 'hidden date';
    dateInput.value = date;
    rowDiv.append(dateInput);
  }

  //Create user cell
  const userIdDiv = document.createElement('div');
  userIdDiv.className = 'col-6 col-md-3';

  const userId = document.createElement('span');
  userId.innerHTML = bookedTime.user_name;
  userId.title = bookedTime.user_name;

  userIdDiv.appendChild(userId);
  rowDiv.appendChild(userIdDiv);

  //Create hours cell
  const hoursDiv = document.createElement('div');
  hoursDiv.className = 'col-2 col-md-1';

  const hoursInput = document.createElement('input');
  hoursInput.name = 'booked_time[hours]';
  hoursInput.id = 'booked_time[hours]';
  hoursInput.className = 'hours';
  hoursInput.value = bookedTime.hours;

  if (newEntry) {
    rowDiv.append(hoursInput);
  }

  if (bookedTime.id) {
    hoursInput.action = '/booked_times/' + bookedTime.id;
  }

  hoursDiv.appendChild(hoursInput);
  rowDiv.appendChild(hoursDiv);

  //Create last updated cell
  const invoiceIdDiv = document.createElement('div');
  invoiceIdDiv.className = 'd-none d-md-block col-md-2';

  if (bookedTime.invoice_id) {
    const invoiceId = document.createElement('a');
    invoiceId.innerHTML = bookedTime.invoice_id;
    invoiceId.href = 'invoices/' + bookedTime.invoice_id + '/edit';
    invoiceIdDiv.appendChild(invoiceId);
  } else if (!bookedTime.has_invoice) {
    const invoiceId = document.createElement('i');
    invoiceId.className = 'fas fa-exclamation-triangle';
    invoiceId.ariaLabel = translate('booking.icons.warn');
    invoiceId.title = translate('booking.icons.warn');
    invoiceIdDiv.appendChild(invoiceId);
  }

  rowDiv.appendChild(invoiceIdDiv);

  //Create description cell
  const descDiv = document.createElement('div');
  descDiv.className = 'd-none d-md-block col-md-3 desc';

  const description = document.createElement('input');
  description.className = 'description';
  description.name = "'booked_time[description]";
  description.id = "'booked_time[description]";

  if (bookedTime.id) {
    description.action = '/booked_times/' + bookedTime.id;
  }

  if (bookedTime.description) {
    description.value = bookedTime.description;
  }
  descDiv.appendChild(description);

  rowDiv.appendChild(descDiv);
  const owner = $('.owner')[0].innerText === 'true';

  if (owner || newEntry) {
    const deleteDiv = document.createElement('div');
    deleteDiv.className = 'col-1 cell';
    const deleteSpan = document.createElement('span');
    const binIcon = document.createElement('i');
    binIcon.className = 'far fa-trash-alt';
    var deleteAction = document.createElement('a');
    deleteAction.className = 'icon destroy';

    // If we have an id, this deletes from the server.
    // If not, it's not been persisted yet, so don't need to call the server and can just remove it in the JS
    if (bookedTime.id) {
      deleteAction.rel = 'nofollow';
      deleteAction.method = 'delete';
      deleteAction.setAttribute('data-method', 'delete');
      deleteAction.href = '/booked_times/' + bookedTime.id;
    }

    deleteAction.appendChild(binIcon);
    deleteSpan.appendChild(deleteAction);
    deleteDiv.appendChild(deleteSpan);

    rowDiv.appendChild(deleteDiv);
  }

  if (newEntry) {
    $form.append(rowDiv);
    $rows.prepend($form);
  } else {
    $rows.append(rowDiv);
  }

  $('.submit-form').prop('disabled', false);
  $('.submit-form').removeClass('disabled');

  return date.toDateString();
};

const selectDefaultHours = date => {
  const day = date.toLocaleString('en-GB', { weekday: 'short' }).toLowerCase();
  const $parentDay = $('.time-day.' + day);

  $parentDay.find('.day-combined').addClass('strong');

  const hours = $parentDay.find('.day-hours')[0].innerText;

  $('#booked_time_hours')[0].value = hours;

  return parseFloat(hours);
};

export { updateTabs, loadBookingHours, populateTimeRow, selectDefaultHours };
